@font-face {
  font-family: icomoon;
  src: url("icomoon.482d6dca.eot");
  src: url("icomoon.482d6dca.eot#iefix") format("embedded-opentype"), url("icomoon.45592589.ttf") format("truetype"), url("icomoon.39273516.woff") format("woff"), url("icomoon.ea22579e.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-measure-ruler-divider:before {
  content: "";
}

.icon-measure-ruler-divider-1:before {
  content: "";
}

.icon-measure-ruler-divider-2:before {
  content: "";
}

.icon-measure-ruler-divider-3:before {
  content: "";
}

.icon-ranking-first:before {
  content: "";
}

.icon-ranking-first-1:before {
  content: "";
}

.icon-ranking-first-2:before {
  content: "";
}

.icon-ranking-first-3:before {
  content: "";
}

.icon-real-estate-building-house:before {
  content: "";
}

.icon-real-estate-building-house-1:before {
  content: "";
}

.icon-real-estate-building-house-2:before {
  content: "";
}

.icon-real-estate-building-house-3:before {
  content: "";
}

.icon-phone-dial:before {
  content: "";
}

.icon-phone-dial-1:before {
  content: "";
}

.icon-phone-dial-2:before {
  content: "";
}

.icon-phone-dial-3:before {
  content: "";
}

.icon-volume-control-full-1:before {
  content: "";
}

.icon-volume-control-full-1-1:before {
  content: "";
}

.icon-volume-control-full-1-2:before {
  content: "";
}

.icon-volume-control-full-1-3:before {
  content: "";
}

.icon-volume-control-settings:before {
  content: "";
}

.icon-volume-control-settings-1:before {
  content: "";
}

.icon-volume-control-settings-2:before {
  content: "";
}

.icon-volume-control-settings-3:before {
  content: "";
}

.icon-music-metronome:before {
  content: "";
}

.icon-music-metronome-1:before {
  content: "";
}

.icon-music-metronome-2:before {
  content: "";
}

.icon-music-metronome-3:before {
  content: "";
}

.icon-equalizer-1:before {
  content: "";
}

.icon-equalizer-1-1:before {
  content: "";
}

.icon-equalizer-1-2:before {
  content: "";
}

.icon-equalizer-1-3:before {
  content: "";
}

.icon-audio-file-volume:before {
  content: "";
}

.icon-audio-file-volume-1:before {
  content: "";
}

.icon-audio-file-volume-2:before {
  content: "";
}

.icon-audio-file-volume-3:before {
  content: "";
}

.icon-smiley-decode:before {
  content: "";
}

.icon-smiley-decode-1:before {
  content: "";
}

.icon-smiley-decode-2:before {
  content: "";
}

.icon-smiley-decode-3:before {
  content: "";
}

.icon-voice-id-user:before {
  content: "";
}

.icon-voice-id-user-1:before {
  content: "";
}

.icon-voice-id-user-2:before {
  content: "";
}

.icon-voice-id-user-3:before {
  content: "";
}

.icon-time-clock-circle:before {
  content: "";
}

.icon-time-clock-circle-1:before {
  content: "";
}

.icon-time-clock-circle-2:before {
  content: "";
}

.icon-time-clock-circle-3:before {
  content: "";
}

.icon-megaphone-1:before {
  content: "";
}

.icon-megaphone-1-1:before {
  content: "";
}

.icon-megaphone-1-2:before {
  content: "";
}

.icon-megaphone-1-3:before {
  content: "";
}

.icon-navigation-left-circle-1_1:before {
  content: "";
}

.icon-navigation-left-circle-1_1-1:before {
  content: "";
}

.icon-navigation-left-circle-1_1-2:before {
  content: "";
}

.icon-navigation-left-circle-1_1-3:before {
  content: "";
}

.icon-delete-2:before {
  content: "";
}

.icon-delete-2-1:before {
  content: "";
}

.icon-delete-2-2:before {
  content: "";
}

.icon-delete-2-3:before {
  content: "";
}

.icon-calendar-date:before {
  content: "";
}

.icon-calendar-date-1:before {
  content: "";
}

.icon-calendar-date-2:before {
  content: "";
}

.icon-calendar-date-3:before {
  content: "";
}

.icon-button-pause:before {
  content: "";
}

.icon-button-pause-1:before {
  content: "";
}

.icon-button-pause-2:before {
  content: "";
}

.icon-button-pause-3:before {
  content: "";
}

.icon-Cardinal-Number:before {
  content: "";
}

.icon-Cardinal-Number-1:before {
  content: "";
}

.icon-Cardinal-Number-2:before {
  content: "";
}

.icon-Cardinal-Number-3:before {
  content: "";
}

.icon-Count-Out:before {
  content: "";
}

.icon-Count-Out-1:before {
  content: "";
}

.icon-Count-Out-2:before {
  content: "";
}

.icon-Count-Out-3:before {
  content: "";
}

.icon-Female-Adult:before {
  content: "";
}

.icon-Female-Adult-1:before {
  content: "";
}

.icon-Female-Adult-2:before {
  content: "";
}

.icon-Female-Adult-3:before {
  content: "";
}

.icon-Female-Child:before {
  content: "";
}

.icon-Female-Child-1:before {
  content: "";
}

.icon-Female-Child-2:before {
  content: "";
}

.icon-Female-Child-3:before {
  content: "";
}

.icon-Fraction:before {
  content: "";
}

.icon-Fraction-1:before {
  content: "";
}

.icon-Fraction-2:before {
  content: "";
}

.icon-Fraction-3:before {
  content: "";
}

.icon-Male-Adult:before {
  content: "";
  color: #324661;
}

.icon-Male-Adult-1:before {
  content: "";
  color: #1e7eb9;
}

.icon-Male-Adult-2:before {
  content: "";
  color: #8f97a1;
}

.icon-Male-Adult-3:before {
  content: "";
  color: #fff;
}

.icon-Male-Child:before {
  content: "";
  color: #324661;
}

.icon-Male-Child-1:before {
  content: "";
  color: #1e7eb9;
}

.icon-Male-Child-2:before {
  content: "";
  color: #8f97a1;
}

.icon-Male-Child-3:before {
  content: "";
  color: #fff;
}

.icon-Pitch:before {
  content: "";
}

.icon-Pitch-1:before {
  content: "";
}

.icon-Pitch-2:before {
  content: "";
}

.icon-Pitch-3:before {
  content: "";
}

.icon-Spell-Out:before {
  content: "";
}

.icon-Spell-Out-1:before {
  content: "";
}

.icon-Spell-Out-2:before {
  content: "";
}

.icon-Spell-Out-3:before {
  content: "";
}

.icon-ssml:before {
  content: "";
}

.icon-ssml-1:before {
  content: "";
}

.icon-ssml-2:before {
  content: "";
}

.icon-ssml-3:before {
  content: "";
}

/*# sourceMappingURL=index.8e46469d.css.map */
