@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?i10fh');
  src:  url('icomoon.eot?i10fh#iefix') format('embedded-opentype'),
    url('icomoon.ttf?i10fh') format('truetype'),
    url('icomoon.woff?i10fh') format('woff'),
    url('icomoon.svg?i10fh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-measure-ruler-divider:before {
  content: "\e900";
}
.icon-measure-ruler-divider-1:before {
  content: "\e901";
}
.icon-measure-ruler-divider-2:before {
  content: "\e902";
}
.icon-measure-ruler-divider-3:before {
  content: "\e903";
}
.icon-ranking-first:before {
  content: "\e904";
}
.icon-ranking-first-1:before {
  content: "\e905";
}
.icon-ranking-first-2:before {
  content: "\e906";
}
.icon-ranking-first-3:before {
  content: "\e907";
}
.icon-real-estate-building-house:before {
  content: "\e908";
}
.icon-real-estate-building-house-1:before {
  content: "\e909";
}
.icon-real-estate-building-house-2:before {
  content: "\e90a";
}
.icon-real-estate-building-house-3:before {
  content: "\e90b";
}
.icon-phone-dial:before {
  content: "\e90c";
}
.icon-phone-dial-1:before {
  content: "\e90d";
}
.icon-phone-dial-2:before {
  content: "\e90e";
}
.icon-phone-dial-3:before {
  content: "\e90f";
}
.icon-volume-control-full-1:before {
  content: "\e910";
}
.icon-volume-control-full-1-1:before {
  content: "\e911";
}
.icon-volume-control-full-1-2:before {
  content: "\e912";
}
.icon-volume-control-full-1-3:before {
  content: "\e913";
}
.icon-volume-control-settings:before {
  content: "\e914";
}
.icon-volume-control-settings-1:before {
  content: "\e915";
}
.icon-volume-control-settings-2:before {
  content: "\e916";
}
.icon-volume-control-settings-3:before {
  content: "\e917";
}
.icon-music-metronome:before {
  content: "\e918";
}
.icon-music-metronome-1:before {
  content: "\e919";
}
.icon-music-metronome-2:before {
  content: "\e91a";
}
.icon-music-metronome-3:before {
  content: "\e91b";
}
.icon-equalizer-1:before {
  content: "\e91c";
}
.icon-equalizer-1-1:before {
  content: "\e91d";
}
.icon-equalizer-1-2:before {
  content: "\e91e";
}
.icon-equalizer-1-3:before {
  content: "\e91f";
}
.icon-audio-file-volume:before {
  content: "\e920";
}
.icon-audio-file-volume-1:before {
  content: "\e921";
}
.icon-audio-file-volume-2:before {
  content: "\e922";
}
.icon-audio-file-volume-3:before {
  content: "\e923";
}
.icon-smiley-decode:before {
  content: "\e924";
}
.icon-smiley-decode-1:before {
  content: "\e925";
}
.icon-smiley-decode-2:before {
  content: "\e926";
}
.icon-smiley-decode-3:before {
  content: "\e927";
}
.icon-voice-id-user:before {
  content: "\e928";
}
.icon-voice-id-user-1:before {
  content: "\e929";
}
.icon-voice-id-user-2:before {
  content: "\e92a";
}
.icon-voice-id-user-3:before {
  content: "\e92b";
}
.icon-time-clock-circle:before {
  content: "\e92c";
}
.icon-time-clock-circle-1:before {
  content: "\e92d";
}
.icon-time-clock-circle-2:before {
  content: "\e92e";
}
.icon-time-clock-circle-3:before {
  content: "\e92f";
}
.icon-megaphone-1:before {
  content: "\e930";
}
.icon-megaphone-1-1:before {
  content: "\e931";
}
.icon-megaphone-1-2:before {
  content: "\e932";
}
.icon-megaphone-1-3:before {
  content: "\e933";
}
.icon-navigation-left-circle-1_1:before {
  content: "\e934";
}
.icon-navigation-left-circle-1_1-1:before {
  content: "\e935";
}
.icon-navigation-left-circle-1_1-2:before {
  content: "\e936";
}
.icon-navigation-left-circle-1_1-3:before {
  content: "\e937";
}
.icon-delete-2:before {
  content: "\e938";
}
.icon-delete-2-1:before {
  content: "\e939";
}
.icon-delete-2-2:before {
  content: "\e93a";
}
.icon-delete-2-3:before {
  content: "\e93b";
}
.icon-calendar-date:before {
  content: "\e93c";
}
.icon-calendar-date-1:before {
  content: "\e93d";
}
.icon-calendar-date-2:before {
  content: "\e93e";
}
.icon-calendar-date-3:before {
  content: "\e93f";
}
.icon-button-pause:before {
  content: "\e940";
}
.icon-button-pause-1:before {
  content: "\e941";
}
.icon-button-pause-2:before {
  content: "\e942";
}
.icon-button-pause-3:before {
  content: "\e943";
}
.icon-Cardinal-Number:before {
  content: "\e944";
}
.icon-Cardinal-Number-1:before {
  content: "\e945";
}
.icon-Cardinal-Number-2:before {
  content: "\e946";
}
.icon-Cardinal-Number-3:before {
  content: "\e947";
}
.icon-Count-Out:before {
  content: "\e948";
}
.icon-Count-Out-1:before {
  content: "\e949";
}
.icon-Count-Out-2:before {
  content: "\e94a";
}
.icon-Count-Out-3:before {
  content: "\e94b";
}
.icon-Female-Adult:before {
  content: "\e94c";
}
.icon-Female-Adult-1:before {
  content: "\e94d";
}
.icon-Female-Adult-2:before {
  content: "\e94e";
}
.icon-Female-Adult-3:before {
  content: "\e94f";
}
.icon-Female-Child:before {
  content: "\e950";
}
.icon-Female-Child-1:before {
  content: "\e951";
}
.icon-Female-Child-2:before {
  content: "\e952";
}
.icon-Female-Child-3:before {
  content: "\e953";
}
.icon-Fraction:before {
  content: "\e954";
}
.icon-Fraction-1:before {
  content: "\e955";
}
.icon-Fraction-2:before {
  content: "\e956";
}
.icon-Fraction-3:before {
  content: "\e957";
}
.icon-Male-Adult:before {
  content: "\e958";
  color: #324661;
}
.icon-Male-Adult-1:before {
  content: "\e959";
  color: #1e7eb9;
}
.icon-Male-Adult-2:before {
  content: "\e95a";
  color: #8f97a1;
}
.icon-Male-Adult-3:before {
  content: "\e95b";
  color: #fff;
}
.icon-Male-Child:before {
  content: "\e95c";
  color: #324661;
}
.icon-Male-Child-1:before {
  content: "\e95d";
  color: #1e7eb9;
}
.icon-Male-Child-2:before {
  content: "\e95e";
  color: #8f97a1;
}
.icon-Male-Child-3:before {
  content: "\e95f";
  color: #fff;
}
.icon-Pitch:before {
  content: "\e960";
}
.icon-Pitch-1:before {
  content: "\e961";
}
.icon-Pitch-2:before {
  content: "\e962";
}
.icon-Pitch-3:before {
  content: "\e963";
}
.icon-Spell-Out:before {
  content: "\e964";
}
.icon-Spell-Out-1:before {
  content: "\e965";
}
.icon-Spell-Out-2:before {
  content: "\e966";
}
.icon-Spell-Out-3:before {
  content: "\e967";
}
.icon-ssml:before {
  content: "\e968";
}
.icon-ssml-1:before {
  content: "\e969";
}
.icon-ssml-2:before {
  content: "\e96a";
}
.icon-ssml-3:before {
  content: "\e96b";
}
